<template>
  <div>
    <TB :tb_config="tb">
      <e-columns>
        <e-column
          field="id"
          headerText="ID"
          type="number"
          :isPrimaryKey="true"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="is_active"
          :headerText="$t('models.lot.is_active')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="productproducer_id"
          :headerText="$t('models.productproducer.name')"
          :allowEditing="false"
          :foreignKeyValue="'translations.' + this.$i18n.locale + '.name'"
          :dataSource="ProductData"
          foreignKeyField="id"
          editType="textedit"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <!-- 
          foreignKeyField="id"
          :dataSource="categoryData"
          :template="categoryTemplate" -->
        <e-column
          v-bind:field="'translations.' + this.$i18n.locale + '.name'"
          :headerText="$t('models.lot.name')"
          type="string"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <!-- :template="nameTemplate" -->
        <!-- <e-column
          field="category_id"
          headerText="Categoria"
          v-bind:foreignKeyValue="'translations.' + this.$i18n.locale + '.name'"
          foreignKeyField="id"
          :dataSource="categoryData"
          :template="categoryTemplate"
          clipMode="EllipsisWithTooltip"
        ></e-column> -->
        <e-column
          field="price"
          type="number"
          :headerText="$t('models.lot.price')"
          clipMode="EllipsisWithTooltip"
          :template="PriceEditTemplate"
        ></e-column>
        <e-column
          field="price_len"
          type="number"
          :headerText="$t('models.lot.price_len')"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="price_unit"
          :headerText="$t('models.lot.price_unit')"
          foreignKeyValue="label"
          :dataSource="Units"
          foreignKeyField="value"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="price_len_mol"
          :headerText="$t('models.lot.price_len_mol')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>

        <e-column
          field="price_sugg"
          type="number"
          :headerText="$t('models.lot.price_sugg')"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="pw"
          :headerText="$t('models.lot.pw')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="pw_len"
          type="number"
          :headerText="$t('models.lot.pw_len')"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="pw_unit"
          :headerText="$t('models.lot.pw_unit')"
          foreignKeyValue="label"
          :dataSource="Units"
          foreignKeyField="value"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="ts_created"
          :headerText="$t('common.table.created')"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="ts_modified"
          :headerText="$t('common.table.last_modified')"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
      </e-columns>
    </TB>
  </div>
</template>

<script>
import Vue from "vue";
import TB from "../../../common/TB";
import { GetDataManager } from "../../../ds/index";
import { DataManager } from "@syncfusion/ej2-data";

export default Vue.extend({
  name: "Products",
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_producer_products_lots", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            "translations/it/name",
            "translations/it/lot_description",
            "id",
          ],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        },
        toolbar: ["Edit", "Update", "Cancel"],
        toolbarOptions: [
          // {
          //   text: "Refresh",
          //   tooltipText: "Refresh data",
          //   prefixIcon: "e-expand",
          //   id: "refresh",
          // },
          // "Add",
          "Edit",
          // "Delete",
          "Update",
          "Cancel",
          "Search",
          "ColumnChooser",
        ],
      },
      ProductData: GetDataManager("role_producer_products", [
        this.$store.state.role.id,
      ]),
      Units: new DataManager([
        { value: 1, label: this.$t("models.lot.units.option1") },
        { value: 2, label: this.$t("models.lot.units.option2") },
        { value: 3, label: this.$t("models.lot.units.option3") },
        { value: 4, label: this.$t("models.lot.units.option4") },
        { value: 5, label: this.$t("models.lot.units.option5") },
        { value: 6, label: this.$t("models.lot.units.option6") },
      ]),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
      PriceEditTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                    <input :value="price"/> CHF
                </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              price: function () {
                return this.data.price;
              },
            },
          }),
        };
      },
      // nameTemplate: function () {
      //   return {
      //     template: Vue.component("columnTemplate", {
      //       template: `<div>
      //               <router-link :to="link"><img class="table-img" :src="image" :alt="altImage"/></router-link>
      //               <router-link :to="link">{{name}}</router-link>
      //           </div>`,
      //       data: function () {
      //         return {
      //           data: {},
      //         };
      //       },
      //       computed: {
      //         image: function () {
      //           return this.data.image_thumb;
      //         },
      //         altImage: function () {
      //           return this.data.id;
      //         },
      //         name: function () {
      //           return this.data.translations[this.$i18n.locale].name;
      //         },
      //         link: function () {
      //           return (
      //             "/role/" +
      //             this.$store.state.role.id +
      //             "/products/products/pp_" +
      //             this.data.id
      //           );
      //         },
      //       },
      //     }),
      //   };
      // },
      // categoryTemplate: function () {
      //   return {
      //     template: Vue.component("columnTemplate", {
      //       template: `<div class="image">
      //               <img class="table-img" :src="image" :alt="altImage"/>
      //               {{name}}
      //           </div>`,
      //       data: function () {
      //         return {
      //           data: {},
      //         };
      //       },
      //       computed: {
      //         image: function () {
      //           return this.data.foreignKeyData.image_thumb;
      //         },
      //         altImage: function () {
      //           return this.data.foreignKeyData.id;
      //         },
      //         name: function () {
      //           return this.data.foreignKeyData.translations[this.$i18n.locale]
      //             .name;
      //         },
      //       },
      //     }),
      //   };
      // },
    };
  },
});
</script>
